<template>
  <div id="app">
    <div id="header" style="display:none;">
      <!-- iv-base-images throws an error without this-->
    </div>
    <router-view :staticaddr="staticaddr" :serveraddr="serveraddr" />
  </div>
</template>

<script>
  import jQuery from 'jquery';
  const $ = (window.$ = window.jQuery = jQuery);

  import env from "@/utils/env.js";

  export default {
    name: 'app',
    data() {
      return {
        staticaddr: env.staticAddr,
        serveraddr: env.serverAddr,
      }
    },
  }
</script>

<style lang="scss">

#app {
  font-family: "Lato";
}

</style>