import "bootstrap/dist/css/bootstrap.min.css";

import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";

import 'bootstrap';

//import "@/css/bootstrap-theme.css";

import "video.js/dist/video-js.min.css";

import "@/css/font.css";
import "./css/_shared-02.css";
import "@/css/bedelia-chat-02.css";

import "@/components/ionRangeSlider/css/ion.rangeSlider.css";
import "@/components/ionRangeSlider/css/normalize.css";
import "@/components/ionRangeSlider/css/ion.rangeSlider.skinSimple.css";

import "@/css/org-04.css";
import "@/css/questions-01.css";


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// require("/code/s/css/_shared-02.css");                                 top   rev-stu   rev-aud   re-info                                               Imported on line 11 (main.js)
                                                                                                                                                          //
// require("/code/s/css/audio-01.css");                                                   rev-aud                                                         COMPLETE
// require("/code/s/css/video-js.css");                                         rev-stu                                                                   COMPLETE
// require("/code/s/css/questions-01.css");                                     rev-stu   rev-aud                                                         Imported above
                                                                                                                                                          //
// require("/code/s/css/review-01.css");                                        rev-stu                                                                   COMPLETE
                                                                                                                                                          //
// require("/code/s/staticlib/ionRangeSlider/css/ion.rangeSlider.css");   top   rev-stu                                                                   Imported on line 13 (main.js)
                                                                                                                                                          //
// require("/code/s/vue-components/iv-player/iv-player.css");                   rev-stu                                                                   COMPLETE
                                                                                                                                                          //
// require("/code/s/css/player-01.css");                                        rev-stu                                                                   COMPLETE
                                                                                                                                                          //
// require("/code/s/css/bedelia-chat-02.css");                            top   rev-stu                                                                   Imported on line 12
                                                                                                                                                          //
// require('/code/s/css/org-04.css');                                     top                                                                             Imported above
// require('/code/s/staticlib/ionRangeSlider/css/normalize.css');         top                                                                             Imported above
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
  next()
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
